import request from '@/utils/request'


// 活动列表
export function bonus_list_api(data) {
  return request({
      url: '/bonus/list',
      method: 'post',
      data
  })
}